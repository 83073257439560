<template>
	<div class="voting-power">
		<div class="header">
			<div class="left">
				<span class="cap-text" v-if="user.name">{{
					getCapitalName
				}}</span>
				<img v-else src="/img/default.png" alt="" />
				<div>
					<h3>{{ formatAddressWallet }}</h3>
					<span>Address</span>
				</div>
			</div>
			<div class="right">
				<h3 :title="numberWithCommas(totalVP)">
					{{ numberLimitlength(totalVP, 11) }}
					<span class="vp">VP</span>
				</h3>
				<span>Total Voting Power</span>
			</div>
		</div>
		<div class="main">
			<BaseAssets :assetsData="assetsPower.dvi">
				<label>Number of DVI</label>
				<div
					class="assets-value"
					:title="numberWithCommas(assetsPower.dvi.total)"
				>
					{{ numberLimitlength(assetsPower.dvi.total, 11) }}
					<span class="unit">DVI</span>
				</div>
			</BaseAssets>
			<BaseAssets :assetsData="assetsPower.land">
				<label>Number of Land(s)</label>
				<div
					class="assets-value"
					:title="numberWithCommas(assetsPower.land.total)"
				>
					{{ numberLimitlength(assetsPower.land.total, 11) }}
				</div>
			</BaseAssets>
			<BaseAssets :assetsData="assetsPower.building">
				<label>Number of Building(s)</label>
				<div
					class="assets-value"
					:title="numberWithCommas(assetsPower.building.total)"
				>
					{{ numberLimitlength(assetsPower.building.total, 11) }}
				</div>
			</BaseAssets>
			<BaseAssets :assetsData="assetsPower.combatPower">
				<label>Rank</label>
				<div
					class="assets-value"
					:title="numberWithCommas(assetsPower.combatPower.total)"
				>
					{{ numberLimitlength(assetsPower.combatPower.rank, 11) }}
				</div>
			</BaseAssets>
		</div>
	</div>
</template>

<script>
import BaseAssets from './BaseAssets.vue'
import { getAssetsVotePower } from '@/services/votepower.js'
import { fromHexToChainId } from '@/features/Common.js'

import WalletAPI from '@/features/WalletAPI.js'
var walletAPI = new WalletAPI()
import { BITSKI, FORTMATIC, WALLETCONNECT } from '@/features/Common'
import { COINBASE, METAMASK, renderNetworkName } from '@/features/Common.js'

import {
	bitski,
	fortmaticProvider,
	walletConnectProvider,
} from '@/features/Connectors'
import axios from 'axios'

export default {
	name: 'VotingPower',
	components: {
		BaseAssets,
	},
	data() {
		return {
			user:
				this.$store.state.userInfo.wallet_addr ==
				this.$route.params.address
					? this.$store.state.userInfo
					: {
							wallet_addr: this.$route.params.address,
					  },
			assetsPower: {
				dvi: {
					type: 'DVI',
					name: 'DVI',
					img: 'DVI.png',
					total: 0,
					totalVP: 0,
					staking: 0,
				},
				land: {
					type: 'LAND',
					name: 'Land',
					img: 'Land.png',
					total: 0,
					totalVP: 0,
				},
				building: {
					type: 'BUILDING',
					name: 'Building',
					img: 'Building.png',
					total: 0,
					totalVP: 0,
				},
				combatPower: {
					type: 'COMBATPOWER',
					name: 'Combat Power',
					img: 'CombatPower.png',
					rank: 0,
					totalVP: 0,
				},
			},
		}
	},
	created() {
		if (!this.$store.state.userInfo.wallet_addr) {
			this.$router.push({ name: 'Catalyst' })
		} else {
			this.fetchAssets()
		}
	},
	mounted() {
		document.querySelector('.gnb-box-bg').scrollIntoView()
	},
	computed: {
		chainId() {
			return fromHexToChainId(localStorage.getItem('currentNetwork'))
		},
		totalVP() {
			return (
				Number(Math.floor(this.assetsPower.dvi.totalVP)) +
				Number(this.assetsPower.land.totalVP) +
				Number(this.assetsPower.building.totalVP) +
				Number(this.assetsPower.combatPower.totalVP)
			)
		},
		getCapitalName() {
			return _U.getCapitalChar(this.user.name)
		},
		formatAddressWallet() {
			let addressWallet = this.user.wallet_addr
			let firstStr = addressWallet.substring(0, 5)
			let lastStr = addressWallet.substring(
				addressWallet.length - 4,
				addressWallet.length
			)
			return firstStr.concat('...', lastStr)
		},
	},
	methods: {
		numberWithCommas(number) {
			return number ? Number(number).toLocaleString() : 0
		},
		numberLimitlength(number, length) {
			const numberStr = Number(number).toLocaleString()
			if (numberStr.length > length) {
				return numberStr.substring(0, length) + '...'
			} else {
				return Number(number).toLocaleString()
			}
		},
		async fetchAssets() {
			this.mxShowLoading('inf')

			const landVPPromise = getAssetsVotePower('LAND', this.chainId, this.user.wallet_addr)
			const land3rdVPPromise = getAssetsVotePower('LAND3RD', this.chainId, this.user.wallet_addr)
			const buildingVPPromise = getAssetsVotePower('BUILDING', this.chainId, this.user.wallet_addr)
			const combatPowerVPPromise = getAssetsVotePower('COMBATPOWER', this.chainId, this.user.wallet_addr)

			const promises = [landVPPromise, land3rdVPPromise, buildingVPPromise, combatPowerVPPromise]
			const [landVP, land3rdVP, buildingVP, combatPowerVP] = await Promise.all(promises);
			
			this.assetsPower.land.total = Number(landVP.nfts) + Number(land3rdVP.nfts);
			this.assetsPower.land.totalVP = Number(landVP.totalVotePowers) + Number(land3rdVP.totalVotePowers)

			this.assetsPower.building.total = buildingVP.nfts
			this.assetsPower.building.totalVP = Number(buildingVP.totalVotePowers)

			console.log(combatPowerVP);
			this.assetsPower.combatPower.rank = this.assetsPower.combatPower.total = combatPowerVP.nfts
			this.assetsPower.combatPower.totalVP = Number(combatPowerVP.totalVotePowers)

			await this.getDviBalance()
			this.mxCloseLoading()
		},
		getProvider() {
			let provider = null
			const loginBy = window.localStorage.getItem('loginBy')
			switch (loginBy) {
				case FORTMATIC:
					provider = fortmaticProvider.getProvider()
					break
				case WALLETCONNECT:
					provider = walletConnectProvider
					break
				case BITSKI:
					provider = bitski.getProvider()
					break
			}
			return provider
		},
		getNetwork(loginBy) {
			const currentNetwork = window.localStorage.getItem('currentNetwork')
			console.log(window.localStorage.getItem('fortmaticNetwork'))
			const network =
				loginBy === METAMASK || loginBy === COINBASE
					? null
					: renderNetworkName(
							currentNetwork
								? currentNetwork
								: window.localStorage.getItem(
										'fortmaticNetwork'
								  )
					  )
			return network
		},
		async getDviBalance() {
			const account = this.$route.params.address
			const provider = this.getProvider()
			const loginBy = window.localStorage.getItem('loginBy')
			console.log({loginBy})
			const network = this.getNetwork(loginBy)
			console.log({network})
			switch (loginBy) {
				case WALLETCONNECT:
					await walletConnectProvider.enable()
					break
			}
			
			walletAPI.getDviBalance(account, provider, network, (resp) => {				
				if (resp.res_code == 200) {
					this.assetsPower.dvi.total = Number(resp.data.balance)
					this.assetsPower.dvi.totalVP = Number(resp.data.balance)
				}
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.voting-power {
	font-family: 'Montserrat';
	grid-column: span 4;
	.header {
		display: flex;
		gap: 30px;
		justify-content: right;
		margin-bottom: 40px;
		.left {
			display: flex;
			img {
				background: transparent;
				widows: 60px;
				height: 60px;
				margin-right: 16px;
				border-radius: 100%;
			}
			.cap-text {
				height: 60px;
				width: 60px;
				border-radius: 100%;
				text-align: center;
				font-family: 'Montserrat';
				font-size: 30px;
				line-height: 2;
				font-weight: 700;
				background: #6c38ef;
				color: white;
				margin-right: 16px;
			}
			h3 {
				font-size: 24px;
				font-weight: 600;
				color: #000;
				line-height: 1.5;
			}
			span {
				font-size: 14px;
				color: #747276;
			}
		}
		.right {
			h3 {
				font-size: 24px;
				font-weight: 600;
				color: #000;
				line-height: 1.5;
				&:hover {
					cursor: pointer;
				}
				span {
					font-size: 24px;
					color: #6c38ef;
					border: 1px solid #6c38ef;
					border-radius: 6px;
					padding: 1px 8px;
				}
			}
			span {
				margin-top: 4px;
				font-size: 14px;
				color: #747276;
			}
		}
	}
	.main {
		display: grid;
		grid-template-columns: 25% 25% 25% 25%;
		gap: 20px;
		max-width: 1080px;
		height: auto;
		label {
			font-family: 'Montserrat';
			color: #747276;
			font-size: 14px;
			font-weight: 400;
			line-height: 1.5;
		}
		.assets-value {
			display: flex;
			gap: 10px;
			font-family: 'Montserrat';
			color: #000000;
			font-size: 24px;
			font-weight: 700;
			line-height: 1;
			margin: 10px 0 16px;
			.unit {
				top: -2px;
				padding: 4px 8px;
				border: 1px solid #17141a;
				border-radius: 4px;
				font-family: 'Montserrat';
				font-size: 18px;
				font-weight: 700;
				line-height: 1;
				color: #17141a;
			}
		}
	}
}
@include media-max($media_large) {
	// 1200
}

@include media-max($media_medium) {
	// 1024
}

@include media-max($media_small) {
	// 768
	.voting-power {
		.header {
			gap: 15px;
			flex-direction: column;
			margin-bottom: 20px;
		}
		.main {
			grid-template-columns: auto;
			.space {
				display: none;
			}
		}
	}
}
</style>
