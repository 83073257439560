<template>
	<div class="assets">
		<label class="asset-name">{{ assetsData.name }}</label>
		<div class="assets-container">
			<div class="assets-image">
				<img :src="`/img/${assetsData.img}`" alt="" />
			</div>
			<slot></slot>
			<label>Number of VP</label>
			<div
				class="assets-value"
				:title="numberWithCommas(Math.floor(assetsData.totalVP))"
			>
				{{ numberLimitlength(Math.floor(assetsData.totalVP), 11)
				}}<span class="unit">VP</span>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'BaseAssets',
	props: {
		assetsData: {},
	},
	methods: {
		numberWithCommas(number) {
			return number ? number.toLocaleString() : 0
		},
		numberLimitlength(number, length) {
			const numberStr = Number(number).toLocaleString()
			if (numberStr.length > length) {
				return numberStr.substring(0, length) + '...'
			} else {
				return Number(number).toLocaleString()
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.assets {
	.asset-name {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 1.5;
		color: #747276;
	}
	.assets-container {
		margin-top: 8px;
		background: #ffffff;
		border: 1px solid #eef2ff;
		border-radius: 8px;
		padding: 20px;
		&:hover {
			&:hover {
				border: 1px solid #6c38ef;
				box-shadow: 0px 8px 8px rgba(48, 24, 108, 0.2);
				transform: translateY(-5px);
				transition: 0.2s;
				cursor: pointer;
			}
		}
		.assets-image {
			width: 100%;
			height: auto;
			border-radius: 8px;
			margin-bottom: 24px;
			img {
				width: 100%;
				aspect-ratio: 3 / 2;
				border-radius: 8px;
			}
		}
		label {
			font-family: 'Montserrat';
			color: #747276;
			font-size: 14px;
			font-weight: 400;
			line-height: 1.5;
		}
		.assets-value {
			display: flex;
			gap: 10px;
			font-family: 'Montserrat';
			color: #000000;
			font-size: 24px;
			font-weight: 700;
			line-height: 1;
			margin-top: 10px;
			.unit {
				top: -2px;
				padding: 4px 8px;
				border: 1px solid #17141a;
				border-radius: 4px;
				font-family: 'Montserrat';
				font-size: 18px;
				font-weight: 700;
				line-height: 1;
				color: #17141a;
			}
		}
	}
}
</style>
