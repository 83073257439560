import { API_DOMAIN } from './config'
import axios from 'axios'

export function getSnapshotVotePower(payload) {
	return new Promise((resolve, reject) => {
		axios
			.get(`${API_DOMAIN}/api/votepower`, {
				headers: {
					'Access-Control-Allow-Origin': '*',
				},
				params: {
					...payload,
				},
			})
			.then((response) => {
				resolve(response.data)
			})
			.catch((error) => {
				reject(error)
			})
	})
}

export function getAssetsVotePower(type, chainId, addWallet) {
	return new Promise((resolve, reject) => {
		axios
			.get(
				`${API_DOMAIN}/api/votepower/${type}/${chainId}/${addWallet}`,
				{
					headers: {
						'Access-Control-Allow-Origin': '*',
					},
				}
			)
			.then((response) => {
				resolve(response.data)
			})
			.catch((error) => {
				reject(error)
			})
	})
}
